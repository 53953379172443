import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import BlockContent from '@sanity/block-content-to-react';
import SEO from '../components/seo';
import styled from 'styled-components';
import Img from 'gatsby-image';

const AboutPage = styled.main`
	padding: 0 20px 100px;

	@media (min-width: 1024px) {
		padding: 0 50px 50px;
	}
`;

const StudioCarousel = styled.section`
	img {
		max-width: 100%;
		width: 100%;
	}
	margin-bottom: 20px;

	@media (min-width: 1024px) {
		margin-bottom: 50px;
	}
`;

const AboutDescription = styled.section`
	margin: 20px 0;
	h1 {
		font-family: 'GrotexBold';
		font-size: 30px;
		line-height: 30px;
		margin-bottom: 20px;
	}
	p {
		font-size: 20px;
		line-height: 22.5px;
	}

	@media (min-width: 1024px) {
		margin: 0 0 100px;
		h1 {
			font-size: 2.7777777778rem;
			line-height: 3.2rem;
			margin-bottom: 40px;
		}
		p {
			font-size: 1.5rem;
			line-height: 1.8666666667rem;
		}
	}
`;

const AboutSectionWrapper = styled.section`
	display: flex;
	flex-direction: column;
	font-size: 20px;

	@media (min-width: 1024px) {
		flex-direction: row;
		font-size: 1.5rem;
		line-height: 1.8666666667rem;
	}
`;

const AboutSection = styled.aside`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 20px 0 40px;

	&.contact {
		flex-direction: row;
		flex-wrap: wrap;
	}

	&.clients {
		h3 {
			margin-bottom: 20px;
		}
		ul.logos-list {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			li {
				width: 33.333%;
				text-align: center;
				margin-bottom: 20px;

				img {
					max-width: 50px;
					width: 100%;
				}
			}
		}
	}

	@media (min-width: 1024px) {
		flex-direction: row;
		flex-wrap: wrap;
		width: 50%;
		margin: 0;
		&.contact {
			justify-content: flex-start;
		}
		&.clients {
			flex-direction: column;

			ul.logos-list {
				li {
					width: 16.6665%;
					&:nth-last-of-type(6),
					&:nth-last-of-type(5),
					&:nth-last-of-type(4),
					&:nth-last-of-type(3),
					&:nth-last-of-type(2),
					&:nth-last-of-type(1) {
						margin-bottom: 0;
					}
					img {
						max-width: 60px;
						width: 100%;
					}
				}
			}
		}
	}
`;

const ContactGroup = styled.div`
	width: 50%;
	margin-bottom: 20px;
	a {
		color: inherit;
		text-decoration: none;
	}

	@media (min-width: 1024px) {
		width: 50%;
		padding-right: 50px;
		box-sizing: border-box;
		margin: 0;
		align-self: flex-start;
		display: block;

		a:hover {
			text-decoration: underline;
		}
	}
`;

const SectionTitle = styled.h3`
	&.freelance-title {
		margin-top: 1em;

		@media (min-width: 1024px) {
			margin-top: 1.333em;
		}
	}
`;

const About = () => {
	const data = useStaticQuery(graphql`
		{
			sanityContact {
				title
				_rawDescription
				studioImages {
					asset {
						_id
						title
						url
						fluid {
							base64
							aspectRatio
							src
							srcSet
							srcWebp
							srcSetWebp
							sizes
						}
					}
				}
				services
				clients {
					_key
					_type
					title
					logo {
						asset {
							url
							fluid {
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						}
					}
				}
				email
				jobsEmail
				instagram
				behance
				twitter
				vimeo
			}
		}
	`);

	return (
		<Layout aboutPage={true}>
			<SEO title="About" description="" />
			<AboutPage>
				<StudioCarousel>
					{data.sanityContact.studioImages && (
						<React.Fragment>
							{data.sanityContact.studioImages.map((img) => (
								<img key={img.asset._id} alt='Bullpen Studio' src={`${img.asset.url}?auto=format&q=100`} />
							))}
						</React.Fragment>
					)}
				</StudioCarousel>

				<AboutDescription>
					{data.sanityContact._rawDescription && (
						<BlockContent blocks={data.sanityContact._rawDescription} />
					)}
				</AboutDescription>

				<AboutSectionWrapper>
					<AboutSection className="contact">
						<ContactGroup className="email">
							<SectionTitle className="contact-title">Contact:</SectionTitle>
							{data.sanityContact.email && (
								<a href={`mailto:${data.sanityContact.email}`}>
									{data.sanityContact.email}
								</a>
							)}
							<br />
							<SectionTitle className="freelance-title">
								Fulltime, Freelance & Internships:
							</SectionTitle>
							{data.sanityContact.jobsEmail && (
								<a href={`mailto:${data.sanityContact.jobsEmail}`}>
									{data.sanityContact.jobsEmail}
								</a>
							)}
						</ContactGroup>

						<ContactGroup className="social">
							<ul>
								{data.sanityContact.instagram && (
									<li>
										<a
											href={data.sanityContact.instagram}
											target="_blank"
											rel="noreferrer noopener"
										>
											Instagram
										</a>
									</li>
								)}

								{data.sanityContact.vimeo && (
									<li>
										<a
											href={data.sanityContact.vimeo}
											target="_blank"
											rel="noreferrer noopener"
										>
											Vimeo
										</a>
									</li>
								)}

								{data.sanityContact.behance && (
									<li>
										<a
											href={data.sanityContact.behance}
											target="_blank"
											rel="noreferrer noopener"
										>
											Behance
										</a>
									</li>
								)}

								{data.sanityContact.twitter && (
									<li>
										<a
											href={data.sanityContact.twitter}
											target="_blank"
											rel="noreferrer noopener"
										>
											Twitter
										</a>
									</li>
								)}
							</ul>
						</ContactGroup>
					</AboutSection>

					<AboutSection className="clients">
						<SectionTitle>Select Clients:</SectionTitle>
						{data.sanityContact.clients && (
							<ul className="logos-list">
								{data.sanityContact.clients.map((client) => (
									<li key={client._key}>
										<img src={client.logo.asset.url} alt={client.title} />
									</li>
								))}
							</ul>
						)}
					</AboutSection>
				</AboutSectionWrapper>
			</AboutPage>
		</Layout>
	);
};

export default About;
